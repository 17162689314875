// import React, { useState, useEffect } from 'react';
// import { useMutation, useQuery } from '@apollo/client';
// import { DELETE_FAQ, CREATE_FAQ, CREATE_FAQ_TITLE } from '../utils/mutations';
// import { GET_ALL_FAQS } from '../utils/queries';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

// const FAQadmin = () => {
//   const navigate = useNavigate();
//   const [faqTitles, setFAQTitles] = useState([]);

//   const [titleText, setTitleText] = useState('');
//   const [faqText, setFAQText] = useState('');

//   const [createFAQTitleMutation] = useMutation(CREATE_FAQ_TITLE);
//   // const [deleteFAQTitleMutation] = useMutation(DELETE_FAQ_TITLE);
//   const [createFAQMutation] = useMutation(CREATE_FAQ);
//   const [deleteFAQMutation, { data: deleteFAQData }] = useMutation(DELETE_FAQ);

//   const { loading, data, refetch } = useQuery(GET_ALL_FAQS);

//     useEffect(() => {
//     // Check if the token exists
//     const token = localStorage.getItem('token');

//     if (!token) {
//       navigate('/'); // Redirect to Home page if the token is not available
//     }

//     // TODO: Verify the validity of the token by sending a request to the server

//     // If the token is invalid or expired, redirect the user to the login page
//     // if (tokenIsInvalidOrExpired) {
//     //   navigate('/login');
//     // }
//   }, [navigate]);

//   useEffect(() => {
//     if (deleteFAQData) {
//       refetch(); // Refetch FAQs data after deletion
//     }
//   }, [deleteFAQData, refetch]);

//   const handleTitleTextChange = e => {
//     setTitleText(e.target.value);
//   };

//   const handleFAQTextChange = e => {
//     setFAQText(e.target.value);
//   };

//   const createFAQTitle = async () => {
//     try {
//       const { data } = await createFAQTitleMutation({
//         variables: {
//           text: titleText,
//         },
//       });

//       const createdTitle = data.createFAQTitle;
//       console.log('FAQ title created:', createdTitle);

//       setFAQTitles([...faqTitles, createdTitle]);

//       setTitleText('');

//       return createdTitle._id;
//     } catch (error) {
//       console.error('Error creating FAQ title:', error);
//       return null;
//     }
//   };

//   useEffect(() => {
//     if (data) {
//       setFAQTitles(data.getAllFAQs);
//     }
//   }, [data]);

//   const handleCreateFAQ = async () => {
//     const titleId = await createFAQTitle();

//     if (titleId) {
//       try {
//         const { data } = await createFAQMutation({
//           variables: {
//             titleId: titleId,
//             text: faqText,
//           },
//         });

//         const createdFAQ = data.createFAQ;
//         console.log('FAQ created:', createdFAQ);

//         setFAQTitles(prevFAQTitles => {
//           const updatedTitles = prevFAQTitles.map(title => {
//             if (title._id === titleId) {
//               return {
//                 ...title,
//                 faqs: [...(title.faqs || []), createdFAQ],
//               };
//             }
//             return title;
//           });
//           return updatedTitles;
//         });

//         setFAQText('');

//         // Refetch FAQs data after creating a new FAQ
//         refetch();
//       } catch (error) {
//         console.error('Error creating FAQ:', error);
//       }
//     }
//   };

//   const handleDelete = async faqId => {
//     try {
//       await deleteFAQMutation({
//         variables: {
//           id: faqId,
//         },
//         onCompleted: data => {
//           console.log('FAQ deleted');

//           // Update the faqTitles state to remove the deleted FAQ
//           const updatedTitles = faqTitles.map(title => {
//             if (title.faqs) {
//               return {
//                 ...title,
//                 faqs: title.faqs.filter(faq => faq._id !== faqId),
//               };
//             }
//             return title;
//           });
//           setFAQTitles(updatedTitles);
//         },
//       });
//     } catch (error) {
//       console.error('Error deleting FAQ:', error);
//     }
//   };

//   return (
//     <div>
//       <div className="flex flex-col mb-20">
//         <h1 className="text-4xl text-center">
//           Welcome Admin to your FAQ Changer
//         </h1>
//         <div className="grid grid-cols-3 group/item cursor-default">
//           <Link
//             to="/FAQ"
//             className="text-3xl mt-4 text-blue-600 text-center col-start-2"
//           >
//             <FontAwesomeIcon icon={faCheck} />
//           </Link>
//           <div className="invisible group-hover/item:visible text text-center col-start-2 text-gray-300">
//             <p>Click the checkmark to see your FAQ changes!</p>
//           </div>
//         </div>
//       </div>
//       <div id="newFAQ" className="flex flex-col md:grid grid-cols-2">
//         <div className="col-start-1 w-11/12 ml-10">
//           <div className="new-card">
//             <h1 className="m-1 w-max p-2 rounded-md text-white moving-gradient font-bold">
//               Create FAQ
//             </h1>
//             <h5 className="card-title text-2xl text-center">FAQ</h5>
//             <h4 className="text-center text-gray-400 font-semibold">
//               Don't forget to add a question mark at the end
//             </h4>
//             <input
//               type="text"
//               placeholder="Question"
//               className="form-control border-2 border-blue-400 rounded-md mt-5 p-3 w-full"
//               value={titleText}
//               onChange={handleTitleTextChange}
//               // Add "name" attribute for SEO
//               name="faqTitle"
//             />
//             <div className="grid grid-rows-2 mt-4">
//               <div className="row-start-1">
//                 <h5 className="card-title text-2xl text-center">Answer</h5>
//                 <textarea
//                   type="text"
//                   placeholder="Answer"
//                   className="form-control border-2 border-blue-400 rounded-md mt-5 p-3 w-full h-full"
//                   value={faqText}
//                   onChange={handleFAQTextChange}
//                   // Add "name" attribute for SEO
//                   name="faqText"
//                 />
//               </div>
//               <button
//                 className="btn btn-primary mt-8 row-start-2"
//                 onClick={handleCreateFAQ}
//               >
//                 <span className="text-2xl hover:bg-green-400 hover:text-white p-2 rounded-lg">
//                   Create FAQ
//                 </span>
//               </button>
//             </div>
//           </div>
//         </div>
//         <div className="col-start-2 justify-self-stretch">
//           <div className="w-10/12">
//             {data?.getAllFAQs?.map((faq, index) => (
//               <div key={faq._id} className="new-card card-body mb-4 relative">
//                 <div>
//                   <p className=" bg-blue-300 text-white w-max pl-3 pr-3 pt-1 pb-1 rounded-full">
//                     {index + 1}
//                   </p>
//                   <h3 className="card-title text-center text-2xl">
//                     {faq.title.text}
//                   </h3>
//                   <p className="m-3">{faq.text}</p>
//                 </div>
//                 <div className="flex flex-col items-center delete-button">
//                   <button
//                     className="group/item btn btn-link btn-delete"
//                     onClick={() => handleDelete(faq._id)}
//                   >
//                     <div className="flex flex-col">
//                       <FontAwesomeIcon
//                         className="text-xl text-red-500"
//                         icon={faTrash}
//                       />
//                       <p className="text-center invisible group-hover/item:visible uppercase text-gray-800 opacity-80">
//                         Delete
//                       </p>
//                     </div>
//                   </button>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FAQadmin;

import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_FAQ, CREATE_FAQ, CREATE_FAQ_TITLE } from '../utils/mutations';
import { GET_ALL_FAQS } from '../utils/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const FAQadmin = () => {
  const [faqTitles, setFAQTitles] = useState([]);

  const [titleText, setTitleText] = useState('');
  const [faqText, setFAQText] = useState('');

  const [createFAQTitleMutation] = useMutation(CREATE_FAQ_TITLE);
  // const [deleteFAQTitleMutation] = useMutation(DELETE_FAQ_TITLE);
  const [createFAQMutation] = useMutation(CREATE_FAQ);
  const [deleteFAQMutation, { data: deleteFAQData }] = useMutation(DELETE_FAQ);

  const { loading, data, refetch } = useQuery(GET_ALL_FAQS);

  useEffect(() => {
    if (deleteFAQData) {
      refetch(); // Refetch FAQs data after deletion
    }
  }, [deleteFAQData, refetch]);

  const handleTitleTextChange = e => {
    setTitleText(e.target.value);
  };

  const handleFAQTextChange = e => {
    setFAQText(e.target.value);
  };

  const createFAQTitle = async () => {
    try {
      const { data } = await createFAQTitleMutation({
        variables: {
          text: titleText,
        },
      });

      const createdTitle = data.createFAQTitle;
      console.log('FAQ title created:', createdTitle);

      setFAQTitles([...faqTitles, createdTitle]);

      setTitleText('');

      return createdTitle._id;
    } catch (error) {
      console.error('Error creating FAQ title:', error);
      return null;
    }
  };

  useEffect(() => {
    if (data) {
      setFAQTitles(data.getAllFAQs);
    }
  }, [data]);

  const handleCreateFAQ = async () => {
    const titleId = await createFAQTitle();

    if (titleId) {
      try {
        const { data } = await createFAQMutation({
          variables: {
            titleId: titleId,
            text: faqText,
          },
        });

        const createdFAQ = data.createFAQ;
        console.log('FAQ created:', createdFAQ);

        setFAQTitles(prevFAQTitles => {
          const updatedTitles = prevFAQTitles.map(title => {
            if (title._id === titleId) {
              return {
                ...title,
                faqs: [...(title.faqs || []), createdFAQ],
              };
            }
            return title;
          });
          return updatedTitles;
        });

        setFAQText('');

        // Refetch FAQs data after creating a new FAQ
        refetch();
      } catch (error) {
        console.error('Error creating FAQ:', error);
      }
    }
  };

  const handleDelete = async faqId => {
    try {
      await deleteFAQMutation({
        variables: {
          id: faqId,
        },
        onCompleted: data => {
          console.log('FAQ deleted');

          // Update the faqTitles state to remove the deleted FAQ
          const updatedTitles = faqTitles.map(title => {
            if (title.faqs) {
              return {
                ...title,
                faqs: title.faqs.filter(faq => faq._id !== faqId),
              };
            }
            return title;
          });
          setFAQTitles(updatedTitles);
        },
      });
    } catch (error) {
      console.error('Error deleting FAQ:', error);
    }
  };

  return (
    <div>
      <div className="flex flex-col mb-20">
        <h1 className="text-4xl text-center">
          Welcome Admin to your FAQ Changer
        </h1>
        <div className="grid grid-cols-3 group/item cursor-default">
          <Link
            to="/FAQ"
            className="text-3xl mt-4 text-blue-600 text-center col-start-2"
          >
            <FontAwesomeIcon icon={faCheck} />
          </Link>
          <div className="invisible group-hover/item:visible text text-center col-start-2 text-gray-300">
            <p>Click the checkmark to see your FAQ changes!</p>
          </div>
        </div>
      </div>
      <div id="newFAQ" className="flex flex-col md:grid grid-cols-2">
        <div className="col-start-1 w-11/12 ml-10">
          <div className="new-card">
            <h1 className="m-1 w-max p-2 rounded-md text-white moving-gradient font-bold">
              Create FAQ
            </h1>
            <h5 className="card-title text-2xl text-center">FAQ</h5>
            <h4 className="text-center text-gray-400 font-semibold">
              Don't forget to add a question mark at the end
            </h4>
            <input
              type="text"
              placeholder="Question"
              className="form-control border-2 border-blue-400 rounded-md mt-5 p-3 w-full"
              value={titleText}
              onChange={handleTitleTextChange}
            />
            <div className="grid grid-rows-2 mt-4">
              <div className="row-start-1">
                <h5 className="card-title text-2xl text-center">Answer</h5>
                <textarea
                  type="text"
                  placeholder="Answer"
                  className="form-control border-2 border-blue-400 rounded-md mt-5 p-3 w-full h-full"
                  value={faqText}
                  onChange={handleFAQTextChange}
                />
              </div>
              <button
                className="btn btn-primary mt-8 row-start-2"
                onClick={handleCreateFAQ}
              >
                <span className="text-2xl hover:bg-green-400 hover:text-white p-2 rounded-lg">
                  Create FAQ
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-start-2 justify-self-stretch">
          <div className="w-10/12">
            {data?.getAllFAQs?.map((faq, index) => (
              <div key={faq._id} className="new-card card-body mb-4 relative">
                <div>
                  <p className=" bg-blue-300 text-white w-max pl-3 pr-3 pt-1 pb-1 rounded-full">
                    {index + 1}
                  </p>
                  <h3 className="card-title text-center text-2xl">
                    {faq.title.text}
                  </h3>
                  <p className="m-3">{faq.text}</p>
                </div>
                <div className="flex flex-col items-center delete-button">
                  <button
                    className="group/item btn btn-link btn-delete"
                    onClick={() => handleDelete(faq._id)}
                  >
                    <div className="flex flex-col">
                      <FontAwesomeIcon
                        className="text-xl text-red-500"
                        icon={faTrash}
                      />
                      <p className="text-center invisible group-hover/item:visible uppercase text-gray-800 opacity-80">
                        Delete
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQadmin;

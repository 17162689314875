// import { gql } from '@apollo/client';

// export const LOGIN_MUTATION = gql`
//   mutation Login($email: String!, $password: String!) {
//     login(email: $email, password: $password) {
//       token
//     }
//   }
// `;

// export const CREATE_FAQ = gql`
//   mutation CreateFAQ($text: String!, $titleId: ID!) {
//     createFAQ(text: $text, titleId: $titleId) {
//       _id
//       text
//     }
//   }
// `;

// export const CREATE_FAQ_TITLE = gql`
//   mutation CreateFAQTitle($text: String!) {
//     createFAQTitle(text: $text) {
//       _id
//       text
//     }
//   }
// `;

// export const DELETE_FAQ_TITLE = gql`
//   mutation DeleteFAQTitle($id: ID!) {
//     deleteFAQTitle(id: $id) {
//       _id
//       text
//     }
//   }
// `;

// export const DELETE_FAQ = gql`
//   mutation DeleteFAQ($id: ID!) {
//     deleteFAQ(id: $id) {
//       _id
//     }
//   }
// `;

import { gql } from '@apollo/client';

export const CREATE_FAQ = gql`
  mutation CreateFAQ($text: String!, $titleId: ID!) {
    createFAQ(text: $text, titleId: $titleId) {
      _id
      text
    }
  }
`;

export const CREATE_FAQ_TITLE = gql`
  mutation CreateFAQTitle($text: String!) {
    createFAQTitle(text: $text) {
      _id
      text
    }
  }
`;

export const DELETE_FAQ_TITLE = gql`
  mutation DeleteFAQTitle($id: ID!) {
    deleteFAQTitle(id: $id) {
      _id
      text
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation DeleteFAQ($id: ID!) {
    deleteFAQ(id: $id) {
      _id
    }
  }
`;
import React, { useState, useEffect } from 'react';
import './App.css';
import Main from './components/Main';
import Nav from './components/Nav';
import Motto from './components/Motto';
import NavFAQ from './components/NavFAQ';
import About from './components/About';
import MeetMe from './components/MeetMe';
import Services from './components/Services';
import Contact from './components/Contact';
import FAQ from './components/FAQ';
import FAQadmin from './pages/FAQadmin';
import Footer from './components/Footer';
import Login from './pages/Login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { Helmet } from 'react-helmet';
// .

const httpLink = createHttpLink({
  uri: 'https://grapevine.herokuapp.com/graphql',
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <main>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/FAQ" element={<FAQPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/FAQadmin" element={<FAQAdmin />} />
          </Routes>
          <Footer />
        </Router>
      </main>
    </ApolloProvider>
  );
}

function Home() {
  return (
    <>
      <Helmet>
        <title>The Grapevine Senior Placement Services</title>
        <meta name="description" content="At The Grapevine Senior Placement Services, we specialize in connecting families with the perfect senior care communities in Southeast Michigan." />
      </Helmet>
      <Nav />
      <Main />
      <Motto />
      <About />
      <Services />
      <MeetMe />
      <Contact />
    </>
  );
}

function FAQPage() {
  return (
    <>
      <ApolloProvider client={client}>
        <NavFAQ />
        <FAQ />
      </ApolloProvider>
    </>
  );
}

function LoginPage() {
  return (
    <>
      <NavFAQ />
      <Login />
    </>
  );
}

function FAQAdmin() {
  return (
    <>
      <NavFAQ />
      <FAQadmin />
    </>
  );
}

export default App;

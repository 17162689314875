import React from 'react';
import Kim5 from "../../assets/images/Kim5.svg";

const MeetMe = () => {
  return (
    <section id="MeetMe" className="flex flex-col items-center">
      <div className="meet-me-container flex flex-col items-center mt-20">
        <div className="flex flex-col text-center">
          <header className="meet-me-header text-6xl font-semibold">
            Kimberly Sanders
          </header>
          <h2 className="meet-me-title text-center text-2xl mt-2 text-gray-700">
            Owner / Eldercare Advisor
          </h2>
        </div>
        <div className="flex meet-me-responsive">
          <div className='flex flex-col'>
            <header className="text-3xl font-semibold text-center">
              Introducing our Owner: A Dedicated Benefits Specialist and Senior Care Expert
            </header>
            <div className='flex flex-row items-center mt-10'>
          <div className="meet-me-left-side flex flex-col items-center">
            <img src={Kim5} alt="Kimberly" />
          </div>
          <div className="meet-me-right-side flex flex-col items-center">
            <div className="meet-me-right-side-description">
              <p className="text-2xl">
                Meet{' '}
                <span className="name-color-1 font-semibold">
                  Kimberly Sanders
                </span>
                , the passionate owner of{' '}
                <span className="name-color-3 font-semibold">The</span>{' '}
                <span className="name-color-1 font-semibold">Grape</span>
                <span className="name-color-2 font-semibold">vine</span>
                <span className="name-color-3 font-semibold">
                  {' '}
                  Senior Placement Services
                </span>
                . Kimberly has over 20 years of experience as a paralegal and placement specialist, working with elder law attorneys, social workers, doctors, nurses, hospital administrators, real estate professionals, and owners and operators of independent and assisted living communities throughout the State of Michigan. She has extensive knowledge in benefits, both public and private. She has proudly worked with our Veterans and is well versed in veterans benefits for both veterans and their families. Her objective in starting The Grapevine was to connect individuals with housing and care options that fit individual needs, preferences, and financial objectives. Person centered planned is her strong focus as she understands the challenges of transitioning to a new living environment and is dedicated to making the process easier.
                  </p>
          <p className="text-2xl mt-10 responsive-large-active">
            The Grapevine is symbolic of connection!  Kimberly has established herself in the community as a trusted resource and prides herself in cultivating connections. She provides valuable information and support whether you are looking for homecare services or when making the move to independent or supported housing successful.
          </p>
            </div>
              </div>
              </div>
            </div>
        </div>
        <div className="flex flex-col meet-me-right-side-description responsive">
          <p className="text-2xl mt-10 responsive-large">
            The Grapevine is symbolic of connection!  Kimberly has established herself in the community as a trusted resource and prides herself in cultivating connections. She provides valuable information and support whether you are looking for homecare services or when making the move to independent or supported housing successful.
          </p>
          <p className="text-2xl mt-10">
            Kimberly provides guidance and can connect families to assistance with the physical move, including packing, cleaning and organizing.
          </p>
          <p className="text-2xl mt-10">
            Trust Kimberly's expertise and compassionate approach to help you or your loved ones find the perfect housing solutio.  Contact us today to learn more about how Kimberly can assist you.
          </p>
        </div>

        <div className="flex meet-me-tablet">
          <div className="flex flex-col items-center">
            <div className="flex tablet-img-container justify-center">
              <div className="tablet-img-left flex items-center">
                <img src={Kim5} alt="Kimberly" className="tablet-img" />
              </div>
            </div>
            <div>
            <header className="text-3xl font-semibold text-center mt-10">
              Introducing our Owner: A Dedicated Benefits Specialist and Senior Care Expert
            </header>
              <p className="text-2xl mt-10">
                  Meet{' '}
                  <span className="name-color-1 font-semibold">
                    Kimberly Sanders
                  </span>
                  , the passionate owner of{' '}
                  <span className="name-color-3 font-semibold">The</span>{' '}
                  <span className="name-color-1 font-semibold">Grape</span>
                  <span className="name-color-2 font-semibold">vine</span>
                  <span className="name-color-3 font-semibold">
                    {' '}
                    Senior Placement Services
                  </span>
                  . Kimberly has over 20 years of experience as a paralegal and placement specialist, working with elder law attorneys, social workers, doctors, nurses, hospital administrators, real estate professionals, and owners and operators of independent and assisted living communities throughout the State of Michigan.  She has extensive knowledge in benefits, both public and private.  She has proudly worked with our Veterans and is well versed in veterans benefits for both veterans and their families.  Her objective in starting The Grapevine was to connect individuals with housing and care options that fit individual needs, preferences, and financial objectives. Person centered planned is her strong focus as she understands the challenges of transitioning to a new living environment and is dedicated to making the process easier.
                </p>
              <p className="text-2xl mt-4">
                The Grapevine is symbolic of connection!  Kimberly has established herself in the community as a trusted resource and prides herself in cultivating connections. She provides valuable information and support whether you are looking for homecare services or when making the move to independent or supported housing successful.
              </p>
              <p className="text-2xl mt-4">
                Kimberly provides guidance and can connect families to assistance with the physical move, including packing, cleaning and organizing.
              </p>
              <p className="text-2xl mt-4">
                Trust Kimberly's expertise and compassionate approach to help you or your loved ones find the perfect housing solution. Contact us today to learn more about how Kimberly can assist you.
              </p>
            </div>
          </div>
        </div>

        <div className="flex meet-me-desktop">
          <div className="flex flex-col items-center">
                          <div className="tablet-img-left">
                <img src={Kim5} alt="Kimberly" className="tablet-img" />
              </div>
            <header className="text-3xl font-semibold meet-me-img-header">
              Introducing our Owner: A Dedicated Benefits Specialist and Eldercare Advisor.
            </header>
            {/* <div className="flex tablet-img-container">
              <div className="flex flex-col items-center justify-around tablet-img-right">
                <p className="text-2xl">
                  Meet{' '}
                  <span className="name-color-1 font-semibold">
                    Kimberly Sanders
                  </span>
                  , the passionate owner of{' '}
                  <span className="name-color-3 font-semibold">The</span>{' '}
                  <span className="name-color-1 font-semibold">Grape</span>
                  <span className="name-color-2 font-semibold">vine</span>
                  <span className="name-color-3 font-semibold">
                    {' '}
                    Senior Placement Services
                  </span>
                  . Kimberly has over 20 years of experience as a paralegal and placement specialist, working with elder law attorneys, social workers, doctors, nurses, hospital administrators, real estate professionals, and owners and operators of independent and assisted living communities throughout the State of Michigan.  She has extensive knowledge in benefits, both public and private.  She has proudly worked with our Veterans and is well versed in veterans benefits for both veterans and their families.  Her objective in starting The Grapevine was to connect individuals with housing and care options that fit individual needs, preferences, and financial objectives. Person centered planned is her strong focus as she understands the challenges of transitioning to a new living environment and is dedicated to making the process easier.
                </p>
              </div>
            </div> */}
            <div className='mt-10'>
              <p className="text-2xl">
                  Meet{' '}
                  <span className="name-color-1 font-semibold">
                    Kimberly Sanders
                  </span>
                  , the passionate owner of{' '}
                  <span className="name-color-3 font-semibold">The</span>{' '}
                  <span className="name-color-1 font-semibold">Grape</span>
                  <span className="name-color-2 font-semibold">vine</span>
                  <span className="name-color-3 font-semibold">
                    {' '}
                    Senior Placement Services
                  </span>
                  . Kimberly has over 20 years of experience as a paralegal and placement specialist, working with elder law attorneys, social workers, doctors, nurses, hospital administrators, real estate professionals, and owners and operators of independent and assisted living communities throughout the State of Michigan.  She has extensive knowledge in benefits, both public and private.  She has proudly worked with our Veterans and is well versed in veterans benefits for both veterans and their families.  Her objective in starting The Grapevine was to connect individuals with housing and care options that fit individual needs, preferences, and financial objectives. Person centered planned is her strong focus as she understands the challenges of transitioning to a new living environment and is dedicated to making the process easier.
                </p>
              <p className="text-2xl mt-4">
                The Grapevine is symbolic of connection!  Kimberly has established herself in the community as a trusted resource and prides herself in cultivating connections. She provides valuable information and support whether you are looking for homecare services or when making the move to independent or supported housing successful.
              </p>
              <p className="text-2xl mt-4">
                Kimberly provides guidance and can connect families to assistance with the physical move, including packing, cleaning and organizing.
              </p>
              <p className="text-2xl mt-4">
                Trust Kimberly's expertise and compassionate approach to help you or your loved ones find the perfect housing solution. Contact us today to learn more about how Kimberly can assist you.
              </p>
            </div>
          </div>
        </div>

        <div className="flex meet-me-large-desktop">
          <div className="flex flex-col items-center">
            <div className="flex tablet-img-container">
              <div className="tablet-img-left">
                <img src={Kim5} alt="Kimberly" className="tablet-img" />
              </div>
              <div className="flex flex-col items-center justify-around tablet-img-right">
                <header className="text-3xl font-semibold meet-me-img-header">
                  Introducing our Owner: A Dedicated Benefits Specialist and
                  Senior Care Expert
                </header>
                <p className="text-2xl">
                  Meet{' '}
                  <span className="name-color-1 font-semibold">
                    Kimberly Sanders
                  </span>
                  , the passionate owner of{' '}
                  <span className="name-color-3 font-semibold">The</span>{' '}
                  <span className="name-color-1 font-semibold">Grape</span>
                  <span className="name-color-2 font-semibold">vine</span>
                  <span className="name-color-3 font-semibold">
                    {' '}
                    Senior Placement Services
                  </span>
                  . Kimberly has over 20 years of experience as a paralegal and placement specialist, working with elder law attorneys, social workers, doctors, nurses, hospital administrators, real estate professionals, and owners and operators of independent and assisted living communities throughout the State of Michigan.  She has extensive knowledge in benefits, both public and private.  She has proudly worked with our Veterans and is well versed in veterans benefits for both veterans and their families.  Her objective in starting The Grapevine was to connect individuals with housing and care options that fit individual needs, preferences, and financial objectives. Person centered planned is her strong focus as she understands the challenges of transitioning to a new living environment and is dedicated to making the process easier.
                </p>
              </div>
            </div>
            <div>
              <p className="text-2xl mt-4">
                The Grapevine is symbolic of connection!  Kimberly has established herself in the community as a trusted resource and prides herself in cultivating connections. She provides valuable information and support whether you are looking for homecare services or when making the move to independent or supported housing successful.
              </p>
              <p className="text-2xl mt-4">
                Kimberly provides guidance and can connect families to assistance with the physical move, including packing, cleaning and organizing.
              </p>
              <p className="text-2xl mt-4">
                Trust Kimberly's expertise and compassionate approach to help you or your loved ones find the perfect housing solution. Contact us today to learn more about how Kimberly can assist you.
              </p>
            </div>
          </div>
        </div>

        <div className="flex meet-me-largest">
          <div className="flex flex-col items-center">
            <div className="flex tablet-img-container">
              <div className="tablet-img-left">
                <img src={Kim5} alt="Kimberly" className="tablet-img" />
              </div>
              <div className="flex flex-col items-center justify-between tablet-img-right">
                <header className="text-3xl font-semibold meet-me-img-header">
                  Introducing our Owner: A Dedicated Benefits Specialist and
                  Senior Care Expert
                </header>
                <p className="text-2xl">
                  Meet{' '}
                  <span className="name-color-1 font-semibold">
                    Kimberly Sanders
                  </span>
                  , the passionate owner of{' '}
                  <span className="name-color-3 font-semibold">The</span>{' '}
                  <span className="name-color-1 font-semibold">Grape</span>
                  <span className="name-color-2 font-semibold">vine</span>
                  <span className="name-color-3 font-semibold">
                    {' '}
                    Senior Placement Services
                  </span>
                  . Kimberly has over 20 years of experience as a paralegal and placement specialist, working with elder law attorneys, social workers, doctors, nurses, hospital administrators, real estate professionals, and owners and operators of independent and assisted living communities throughout the State of Michigan.  She has extensive knowledge in benefits, both public and private.  She has proudly worked with our Veterans and is well versed in veterans benefits for both veterans and their families.  Her objective in starting The Grapevine was to connect individuals with housing and care options that fit individual needs, preferences, and financial objectives. Person centered planned is her strong focus as she understands the challenges of transitioning to a new living environment and is dedicated to making the process easier.
                </p>
              </div>
            </div>
            <div>
              <p className="text-2xl mt-4">
                The Grapevine is symbolic of connection!  Kimberly has established herself in the community as a trusted resource and prides herself in cultivating connections. She provides valuable information and support whether you are looking for homecare services or when making the move to independent or supported housing successful.
              </p>
              <p className="text-2xl mt-4">
                Kimberly provides guidance and can connect families to assistance with the physical move, including packing, cleaning and organizing.
              </p>
              <p className="text-2xl mt-4">
                Trust Kimberly's expertise and compassionate approach to help you or your loved ones find the perfect housing solution. Contact us today to learn more about how Kimberly can assist you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeetMe;

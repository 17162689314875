import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

const Main = () => {
  return (
    <section id="Main">
      <div className="main-div flex flex-col items-center">
        {/* <header className="text-center text-white text-6xl font-bold">
          The <span className="name-color-1">Grape</span>
          <span className="name-color-2">vine</span> Senior Placement Services
        </header> */}
        <header className="text-center text-6xl font-bold px-2">
          The Grapevine Senior Placement Services
        </header>
        <ScrollLink
          to="Contact"
          smooth={true}
          duration={2000}
          id="main-link"
          className="mt-10 text-3xl font-bold text-white"
        >
          Connect Today!
        </ScrollLink>
      </div>
    </section>
  );
};

export default Main;

import React, { useRef } from 'react';
import contactUs from '../../assets/images/contact-us.svg';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import logo from "../../assets/other/logo2.png"

const Contact = () => {
  function validatePhoneNumber(phone) {
    const regex = /^(\d{3}[-\)]?)?\d{3}[-]?\d{4}$/;
    return regex.test(phone);
  }

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const form = useRef();

  const checkInfo = e => {
    e.preventDefault();

    let firstName = document.getElementById('contact-name-first').value;
    let lastName = document.getElementById('contact-name-last').value;
    let userPhone = document.getElementById('contact-phone').value;
    let userEmail = document.getElementById('contact-email').value;
    let userMessage = document.getElementById('contact-message').value;

    if (!firstName) {
      Swal.fire({
        icon: 'warning',
        title: 'Must have a First Name!',
      });
      console.log('Must have a First Name!');
    } else if (!lastName) {
      Swal.fire({
        icon: 'warning',
        title: 'Must have a Last Name!',
      });
      console.log('Must have a Last Name!');
    } else if (!validatePhoneNumber(userPhone)) {
      Swal.fire({
        icon: 'warning',
        title: 'Must have a valid Phone Number!',
      });
      console.log('Must have a Phone Number!');
    } else if (!validateEmail(userEmail)) {
      Swal.fire({
        icon: 'warning',
        title: 'Must have a valid Email!',
      });
      console.log('Must have an Email!');
    } else if (!userMessage) {
      Swal.fire({
        icon: 'warning',
        title: 'Must include a Message!',
      });
      console.log('Must have a message!');
    } else {
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_USER_ID
        )
        .then(
          result => {
            console.log(result.text);
            Swal.fire({
              icon: 'success',
              title: 'Message Sent Successfully',
            });
          },
          error => {
            console.log(error.text);
            Swal.fire({
              icon: 'error',
              title: 'Something went wrong 😓',
              text: error.text,
            });
          }
        );
    }
  };

  return (
    <section id="Contact" className="flex">
      <div className="contact-left-side flex justify-center">
        <img src={contactUs} alt="contact" />
      </div>
      <div className="contact-right-side flex flex-col justify-between items-center">
        <div className='flex align-middle justify-center'>
          <img src={logo} alt="logo" className="logo-contact mx-4" />
          <h1 className="text-center text-6xl font-semibold contact-header">
          Contact Us
          </h1>
          <img src={logo} alt="logo" className="logo-contact mx-4" />
        </div>
        <div className="contact-line"></div>
        <h2 className="text-center text-xl font-medium name-color-1">
          (517)-294-5842
        </h2>
        <div className="contact-line"></div>
        <form ref={form} onSubmit={checkInfo} className="contact-form mt-10">
          <label htmlFor="contact-name" className="text-lg font-semibold">
            Your Name
          </label>
          <div className="contact-name flex">
            <div className="flex flex-col" style={{ width: '49.6%' }}>
              <input
                type="text"
                id="contact-name-first"
                name="contact-name-first"
                placeholder="First Name"
                className="mr-2"
              />
            </div>
            <div className="flex flex-col" style={{ width: '49.6%' }}>
              <input
                type="text"
                id="contact-name-last"
                name="contact-name-last"
                placeholder="Last Name"
                className="ml-2"
              />
            </div>
          </div>

          <div className="flex">
            <div className="flex flex-col" style={{ width: '49.6%' }}>
              <label htmlFor="contact-phone" className="text-lg font-semibold">
                Phone
              </label>
              <input
                type="text"
                id="contact-phone"
                name="contact-phone"
                placeholder="Your Number"
                className="mr-2"
              />
            </div>
            <div className="flex flex-col" style={{ width: '49.6%' }}>
              <label
                htmlFor="contact-email"
                className="text-lg font-semibold ml-2"
              >
                Email
              </label>
              <input
                type="text"
                id="contact-email"
                name="contact-email"
                placeholder="Your Email"
                className="ml-2"
              />
            </div>
          </div>

          <label htmlFor="contact-message" className="text-lg font-semibold">
            Message
          </label>
          <textarea
            id="contact-message"
            name="contact-message"
            placeholder="Message"
          ></textarea>

          <button type="submit" className="">
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChessQueen } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer id="Footer">
      <h1 className="text-center py-10 text-white">
        &copy; 2023 by The GrapeVine Senior Placement Services
      </h1>
      <a href="/login" className="footer-login">
        <FontAwesomeIcon
          className="text-transparent hover:text-[#331A22] hover:bg-white p-2 rounded-full"
          icon={faChessQueen}
        />
      </a>
    </footer>
  );
};

export default Footer;

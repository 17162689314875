import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_FAQS } from '../../utils/queries';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FAQ = () => {
  const { loading, error, data } = useQuery(GET_ALL_FAQS);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error fetching FAQs: {error.message}</p>;
  }

  const faqs = data.getAllFAQs;

  return (
    <section id="FAQ" className="mt-20 flex flex-col items-center">
      <h1 className="text-6xl text-center font-semibold">
        Frequently Asked Questions
      </h1>
      <FontAwesomeIcon
        className="m-2 text-6xl faq-icon"
        icon={faQuestionCircle}
      />
      <div className="select-none faq-card-container mt-20 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 mb-40 rounded-xl">
        {faqs.map(faq => (
          <div
            key={faq._id}
            className="text-center bg-white rounded-xl shadow-2xl flex flex-col justify-between faq-card"
          >
            <div className="flex flex-col justify-center h-48 bg-black text-white rounded-t-xl">
              <h2 className="text-3xl font-semibold mb-4 px-4">
                {faq.title.text}
              </h2>
            </div>
            <div className="flex-grow p-6">
              <p className="text-gray-600 text-xl font-semibold">{faq.text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;

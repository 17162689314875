import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import logo from '../../assets/other/logo.svg';

const Nav = () => {
  const [isHomePage, setIsHomePage] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const [offset, setOffset] = useState(-100);
  const [contactOffset, setContactOffset] = useState(-150);

  useEffect(() => {
    setIsHomePage(location.pathname === '/');
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setOffset(-160);
        setContactOffset(300); // Different offset value for contact link on smaller screens
      } else {
        setOffset(-140); // Same offset value for other links on larger screens
        setContactOffset(-140); // Same offset value for contact link on larger screens
      }
    };

    handleResize(); // Call handleResize initially

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav id="Nav" className="flex justify-around items-center">
      <div className="name-logo flex items-center">
        <img src={logo} alt="logo" className="logo" />
        <h1 className="text-3xl font-semibold text-white">
          The <span className="name-color-1">Grape</span>
          <span className="name-color-2">vine</span> Senior Placement Services
        </h1>
      </div>

      {/* Hamburger Menu */}
      <div className="hamburger-menu" onClick={handleMenuToggle}>
        {[1, 2, 3].map(index => (
          <div key={index} className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
        ))}
      </div>

      {/* Close Button */}
      {isMenuOpen && (
        <div className="close-button" onClick={handleMenuClose}>
          {[1, 2].map(index => (
            <div key={index} className="close-line"></div>
          ))}
        </div>
      )}

      {/* Nav Links */}
      <ul
        className={`nav-links flex items-center text-xl font-medium ${
          isMenuOpen ? 'open' : ''
        }`}
      >
        <li className="nav-link mx-4">
          {isHomePage ? (
            <ScrollLink to="Main" smooth={true} duration={2000} offset={offset}>
              Home
            </ScrollLink>
          ) : (
            <Link to="/" className="active-link">
              Home
            </Link>
          )}
        </li>
        <li className="nav-link mx-4">
          <ScrollLink to="About" smooth={true} duration={2000} offset={offset}>
            About Us
          </ScrollLink>
        </li>
        <li className="nav-link mx-4">
          <ScrollLink to="Services" smooth={true} duration={2000} offset={offset}>
            Services
          </ScrollLink>
        </li>
        <li className="nav-link mx-4">
          <ScrollLink to="Contact" smooth={true} duration={2000} offset={contactOffset}>
            Contact
          </ScrollLink>
        </li>
        <li className="nav-link mx-4">
          <Link to="/FAQ" className="active-link">
            FAQ
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;

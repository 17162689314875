import React from 'react';
import grapes from '../../assets/videos/grape.mp4';

const Services = () => {
  return (
    <section id="Services">
      <div className="top-side text-center flex flex-col items-center">
        <header className="top-side-header text-5xl font-semibold">
          Why Choose The GrapeVine Senior Placement Services?
        </header>
        <p className="top-side-description text-2xl font-medium mt-10">
          With numerous senior living options available, it can be overwhelming
          to navigate through the choices on your own. That's where The
          GrapeVine Senior Placement Services comes in. Not only are our process
          and services 100% free for you and your loved ones, but here are a few
          more reasons why you should choose us
        </p>
      </div>
      <div className="bottom-side mt-20 flex justify-evenly">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4">
          <div className="card text-center flex flex-col items-center bg-white rounded-lg shadow-2xl p-6">
            <video autoPlay loop muted className="card-icon">
              <source src={grapes} type="video/mp4" />
            </video>
            <h1 className="service-card-header text-xl font-semibold mt-4">
              {/* Expert Guidance */} Senior Population Expertise and Advocacy
            </h1>
            <p className="service-card-description font-semibold text-gray-600 mt-2">
              {/* Our team has extensive knowledge of the senior living industry and
              keeps up-to-date with the latest developments and best practices.
              We leverage our expertise to find the best possible match for your
              loved one's unique needs. */}
              With over 20 years of experience working with the senior
              population, we have extensive knowledge of the challenges and
              concerns seniors face. We bring our expertise to the table,
              advocating for your needs and providing guidance throughout the
              process. Trust us to be your dedicated partner in finding housing
              solutions that meet your specific requirements.
            </p>
          </div>
          <div className="card text-center flex flex-col items-center bg-white rounded-lg shadow-2xl p-6">
            <video autoPlay loop muted className="card-icon">
              <source src={grapes} type="video/mp4" />
            </video>
            <h1 className="service-card-header text-xl font-semibold mt-4">
              Personalized Approach and Care Management
            </h1>
            <p className="service-card-description font-semibold text-gray-600 mt-2">
              {/* We understand that every individual is unique. Our approach is
              tailored to the specific requirements and preferences of your
              loved ones, ensuring a personalized experience from start to
              finish. */}
              We believe in putting your needs and preferences at the forefront
              of our services. With our person-centered planning approach, we
              design and manage care options tailored to your unique
              requirements. Whether you need assistance with daily living tasks
              or specialized services, we ensure you receive the highest quality
              of care.
            </p>
          </div>
          <div className="card text-center flex flex-col items-center bg-white rounded-lg shadow-2xl p-6">
            <video autoPlay loop muted className="card-icon">
              <source src={grapes} type="video/mp4" />
            </video>
            <h1 className="service-card-header text-xl font-semibold mt-4">
              {/* Extensive Network */} Relocation Support and Resource
              Connection
            </h1>
            <p className="service-card-description font-semibold text-gray-600 mt-2">
              {/* Over the years, we have built strong relationships with various
              senior living communities and care providers. This network allows
              us to connect you with options that align with your needs, saving
              you time and effort in the search process. */}{' '}
              Moving can be a daunting task, especially for seniors. We offer
              guidance and connect you to valuable resources for your physical
              move. From cleaning and organizing to accessing relocation-related
              services, we're here to make your transition smoother and more
              manageable.
            </p>
          </div>
          <div className="card text-center flex flex-col items-center bg-white rounded-lg shadow-2xl p-6">
            <video autoPlay loop muted className="card-icon">
              <source src={grapes} type="video/mp4" />
            </video>
            <h1 className="service-card-header text-xl font-semibold mt-4">
              {/* Peace of Mind */} Benefit Navigation and Affordable Housing
            </h1>
            <p className="service-card-description font-semibold text-gray-600 mt-2">
              {/* The senior placement process can be emotionally challenging. Our
              compassionate team provides support and reassurance, helping you
              navigate the journey with peace of mind. We are here to answer
              your questions, address your concerns, and provide guidance at
              every step. */}{' '}
              We specialize in helping individuals understand and utilize
              benefit programs such as Social Security, Veterans benefits,
              Medicare, and Medicaid to secure affordable housing options. Our
              expertise ensures that you can maximize available benefits and
              find housing that fits your budget.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

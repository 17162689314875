import React, { useState } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@apollo/client';
import { LOGIN_MUTATION } from '../utils/mutations';

const Login = () => {
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [loggedIn, setLoggedIn] = useState(false);
  // const navigate = useNavigate();

  // const [loginMutation, { loading, error }] = useMutation(LOGIN_MUTATION);

  // const handleEmailChange = (e) => {
  //   setEmail(e.target.value);
  // };

  // const handlePasswordChange = (e) => {
  //   setPassword(e.target.value);
  // };

  // const handleLogin = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const { data } = await loginMutation({
  //       variables: { email, password },
  //     });

  //     const { token } = data.login;

  //     // Store the token in local storage or a state management solution
  //     localStorage.setItem('token', token);

  //     // Redirect the user to the authenticated page
  //     navigate('/FAQadmin');
  //   } catch (error) {
  //     alert('Invalid email or password');
  //   }
  // };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    // Hardcoded email and password for login validation
    const allowedEmail = 'grapevine855@gmail.com';
    const allowedPassword = 'Kimberly*GrapeVine855!';

    if (email === allowedEmail && password === allowedPassword) {
      setLoggedIn(true);
      navigate('/FAQadmin');
    } else {
      alert('You found the easter egg 🥚! Or invalid email or password');
      navigate('/');
    }
  };

  if (loggedIn) {
    return <Navigate to="/FAQ" />;
  }

  return (
    <div className="h-screen" id="Login">
      <h1 className="col-start-2 text-center text-3xl mt-10 text-black">
        Welcome to your Admin Login Page
        <Link to="/">
          <button>
            <FontAwesomeIcon
              className="text-[#331A22] ml-2 rounded-full"
              icon={faHome}
            />
          </button>
        </Link>
      </h1>
      <form className="login-card" onSubmit={handleLogin}>
        <div className="flex flex-col input-container">
          <label className="mt-2 text-2xl text-start" htmlFor="email">
            Email:
          </label>
          <input
            className="pl-2 p-2 border-2 border-gray-500 rounded"
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            name="email"
          />
        </div>
        <br />
        <div className="flex flex-col input-container">
          <label className="mt-2 text-2xl text-start" htmlFor="password">
            Password:
          </label>
          <input
            className="pl-2 p-2 border-2 border-gray-500 rounded"
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            name="password"
          />
        </div>
        <br />
        <button
          className="text-2xl hover:bg-emerald-500 hover:text-white rounded-xl"
          type="submit"
        >
          Login
        </button>
        <p className="rows-start-3 col-span-3 text-center text-xs">
          If you are NOT the admin of this page click{' '}
          <a className="text-lg text-blue-500" href="/">
            Here.
          </a>
        </p>
      </form>
    </div>
  );
};

export default Login;

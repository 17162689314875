// import { gql } from '@apollo/client';

// export const GET_FAQS = gql`
//   query GetFAQs {
//     faqs {
//       _id
//       text
//       titleId {
//         _id
//         text
//       }
//     }
//   }
// `;

// export const GET_TITLES = gql`
//   query GetFAQTitles {
//     faqTitles {
//       _id
//       text
//     }
//   }
// `;

// export const GET_ALL_FAQS = gql`
//   query GetAllFAQs {
//     getAllFAQs {
//       _id
//       text
//       titleId
//       title {
//         _id
//         text
//       }
//     }
//   }
// `;

import { gql } from '@apollo/client';

export const GET_FAQS = gql`
  query GetFAQs {
    faqs {
      _id
      text
      titleId {
        _id
        text
      }
    }
  }
`;

export const GET_TITLES = gql`
  query GetFAQTitles {
    faqTitles {
      _id
      text
    }
  }
`;

export const GET_ALL_FAQS = gql`
  query GetAllFAQs {
    getAllFAQs {
      _id
      text
      titleId
      title {
        _id
        text
      }
    }
  }
`;
import React from 'react';

const Motto = () => {
  return (
    <section id="Motto" className="flex items-center justify-center">
      <h1 className="motto-header text-3xl font-bold text-center italic">
        "Navigating Benefits, Transforming Lives: Empowering Individuals With
        Affordable Housing, Compassionate Care, & No Cost To You"
      </h1>
    </section>
  );
};

export default Motto;

import React from 'react';
import AboutUs4 from "../../assets/images/about-us-4.svg";

const About = () => {
  return (
    <section id="About" className="flex">
      <div className="left-side flex flex-col justify-center">
        <h1 className="left-side-header text-4xl font-semibold">
          Personalized Senior Care Solutions: Matching Families with the Ideal
          Community for Their Loved Ones.
        </h1>
        {/* <p className="left-side-description text-2xl mt-10">
          At The GrapeVine Senior Placement Services, we specialize in
          personalized senior care solutions, connecting families with the ideal
          communities for their loved ones. With a deep understanding of
          individual needs and preferences, we conduct comprehensive assessments
          to consider factors such as medical requirements, lifestyle choices,
          and social activities. Leveraging our extensive network of senior care
          communities, we provide a range of options that cater to your loved
          ones' specific needs. Our goal is to ensure they receive the necessary
          care in a warm, supportive, and enriching environment where they can
          thrive. Trust us to guide you with expert advice, compassionate
          support, and peace of mind as we discover the perfect senior care
          community together.
        </p> */}
        <p className="left-side-description text-2xl mt-10">
          At The Grapevine Senior Placement Services, we specialize in
          connecting families with the perfect senior care communities in Southeast Michigan. With a deep understanding of individual
          needs and preferences, our comprehensive assessments consider factors
          like medical requirements, lifestyle choices, and social activities.
          Leveraging our extensive network, we offer a range of options tailored
          to your loved ones' specific needs. What sets us apart is that our
          services are 100% free, ensuring that finding the right senior care
          community is accessible to all. Trust us to provide expert advice,
          compassionate support, and peace of mind as we guide you in
          discovering the ideal environment where your loved ones can thrive.
        </p>
        <img
          src={AboutUs4}
          alt="About us"
          className="about-img about-img-active"
        />
      </div>
      <div className="right-side">
        <img
          src={AboutUs4}
          alt="About us"
          className="about-img about-img-responsive"
        />
      </div>
    </section>
  );
};

export default About;
